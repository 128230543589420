@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap');

:root {
    --text-main: white;
    --text-secondary: #babec2;
    --text-tertiary: #7a7d80;
  }
  
#root{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

body {
    height: 100vh;
    width: 100vw;
    background-color: #333944;
    font-family: 'Arimo', sans-serif;
    font-weight: 400;
    display: flex;
    color: white;
    overflow: hidden;
}

.content{
    min-width: 300px;
}



.userBar {
    background: #333944;
    font-size: calc(0.4em + 0.5vmin);
    margin-right: calc(0.3em + 0.3vmin);
    padding: 0.3rem 1rem 0.3rem 1rem;
    border-radius: 2rem;
}

.formCard {
    background-color: rgba(0, 0, 0, 0.15);
    max-width: 90vw;
    width: 30rem;
    padding: 2rem;
    border-radius: 1.5rem;
    
}

.formContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    
}



.editbar{
    width: 100%;
    display: flex;
    padding-left: 0.25rem;
    
}

.btn-summary{
    
    color: white;
    border: none;
    flex-shrink: 1;
}



.exdropdown{
    width: 50%;
    background: none;
    border: none;
}

.modalButtonBar{
    display: flex;
    gap: 0.25rem;
    margin-left: auto;
}

.SVGwhite path {
    stroke: white;
}

.SVGsecondary path {
    stroke: var(--text-secondary);
}




.dropdownIcons{
    margin-right: 0.75rem;
    margin-bottom: 0.1rem;
}

.exerciseNameForm{
    color: var(--text-secondary);
    background: none;
    border: none;
    font-size: 1rem;
    width: 100%;
    padding: none;
    text-overflow: ellipsis;
}

.exerciseWeightForm{
    
    box-sizing: border-box;
    background: none;
    border: none;
    text-align: center;
    color: var(--text-secondary);
    font-size: 1rem;
    margin: 0.2rem;
    width: 100%;
    height: 100%;
    
}

.holder{
    width: 100%;
    display: flex;
}

.holder-lefthalf{
    width: 55%;
    border-radius: 2rem 0rem 0rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.holder-righthalf{
    width: 25%;
    font-size: 1rem;
    border-radius: 0rem 2rem 2rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


.modalLine{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
}

/* .modalLine:nth-child(odd) {
    background-color: #303542;
  }

  .modalLine:nth-child(even) {
    background-color: #2c303d;
  } */

.repcount{
    min-width: 5rem;
    text-align: center;
}


.navbar {
    height: 5rem;
    font-size: 0.8rem;
    font-weight: 400;
   
    background-color: #2b313a;
    color: var(--text-secondary);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 30rem;
    max-width: 90vw;
    
    border-radius: 1rem;
    margin-bottom: 2rem;
}



.navbar-item{
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 0.25rem;
}

.navbar-item-active{
    color: var(--text-main) !important;
    text-decoration: none !important;
 }

 .navbar-item-inactive{
    color: var(--text-secondary) !important;
    text-decoration: none !important;
 }

.navbarbuttons{
    height: 10rem;
    
}




.exercise-container::-webkit-scrollbar {
    display: none;
  }


.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1.5rem); 
    
    
}



.element-grid{
    background-color: #333944;
    padding: 0.5rem;
    border-radius: 1rem;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.element-grid-2col{
    border-radius: 1rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
}



.element-stat{
    background-color: #333944;
    padding: 0.5rem;
    border-radius: 1rem;
    font-weight: 600;
    aspect-ratio: 1 / 1;
    font-size: 2rem;
}

.exercise-item{ 
    border: 0.15rem solid rgb(30, 167, 71); 
    padding: 0.5rem;
    border-radius: 1rem;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
}

.summaryTile {
    height: 100%;
    border-radius: 1rem;
    background-color: #333944;
    
    scroll-snap-align: start;
    font-size: 0.9rem;
    padding-left: 0.5rem;
    text-align: center;
}

.element-grid-3col{
    border-radius: 1rem;
    display: grid;
    gap: 1rem;
    grid-template-rows: 1fr 1fr;
    aspect-ratio: 2/1;
}

.activities-stat{
    background-color: #333944;
    padding: 0.8rem;
    font-size: 1rem;
    
    border-radius: 1rem;
    display: flex;

}

.calendar{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0.5rem;
    padding: 0.75rem;
    font-size: 0.9rem;
}

.calendarLabel{
    text-align: center;
    font-size: 0.9rem;
    color: #adb0b3;
}

.calendarDay{
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7a7d80;
    
}

.calendarDayActive{
    background:  rgb(30, 167, 71); 
    color: white;
}

.calendarDayToday{
    background:  rgb(255, 0, 0); 
    color: white;
}

.element{
    display: flex;
    width: 30rem;
    max-width: 90vw;
    gap: 1rem;
    flex-direction: column;
    background-color: #2b313a;
    border-radius: 1.5rem;
    padding: 1rem;
   
}

.noExercises{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    color: var(--text-tertiary);
    flex-direction: column;
    gap: 0.5rem;
}



.summaryLine {
    height: fit-content;
    width: 28rem;
    max-width: 82vw;
    border-radius: 1rem;
    transition: scale 200ms;
    display: flex;
    flex-direction: column;
    
}

.exerciseNameTag{
    padding: 0.25rem;
    display: flex;
    align-items: center;
    color: var(--text-secondary);
}

.element-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    background-color: #333944;
  }



.summaryLine:last-child {
    scroll-snap-align: end;
}



.summaryLine::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.summary-top{
   padding: 0.5rem;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   
}

.summary-bottom{
    padding: 0rem 0.3rem 0.3rem 0.3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
   /*  -webkit-mask-image: linear-gradient(to right, black 90%, transparent 100%);
     mask-image: linear-gradient(to right, black 90%, transparent 100%); */
 }

 .summary-bottom:empty {
    display: none;
}



.summaryLabel.weight{
    font-size: 1rem;
    font-weight: 500;
    
    
}

.weightbar{
    
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: none;
}



.summaryLabel.weight.color0{
    background: rgb(197, 107, 34);
}

.summaryLabel.weight.color1{
    background: rgb(113, 47, 175);
}

.summaryLabel.weight.color2{
    background: rgb(21, 146, 48);
}

.summaryLabel.weight.color3{
    background: rgb(52, 165, 173);
}

.infobadge {
    margin: 0.3rem 0.3rem 0.3rem 0.3rem;
    padding: 0.2rem 0.8rem 0.2rem 0.8rem;
    border-radius: 1.5rem;
    font-size: 1rem; 
    
}

.infobadge.color0{
    border: 0.15rem solid rgb(30, 167, 71); 
}

.infobadge.color1{
    border: 0.15rem solid rgb(156, 67, 240);   
}

.infobadge.color2{
    border: 0.15rem solid rgb(206, 116, 13);   
}

.modalbutton{
    padding: 0 1rem 0 1rem;
    background: transparent;
    border: none;
    color: var(--text-secondary);
    border-radius: 9999px;
    margin: 0.5rem;
}

.modalActionButton{
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    background: transparent;
    border-radius: 9999px;
    border: none;
    
}






.clock-bottom{
    padding: 0.5rem;
    background-color: #272c33;
    width: 100%;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
   
    gap: 0.5rem;
 }
 
 .clock-top{
     padding: 0.3rem 0.3rem 0.3rem 0.3rem;
     width: 100%;
     display: flex;
     flex-direction: row;
     font-size: 4rem;
     align-items: center;
     justify-content: center;
  }

  .SummaryActionButton{
    
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    font-size: 0.6rem;
    border: none;
    color: white;
    padding: 0.5rem;
    border-radius: 1rem;
    
}




 
.summaryNameLabel{
    border-radius: 1rem;
    padding: 0rem 0.5rem 0rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    
    font-size: 1rem;
}
 
 .summaryLabel{
     
     padding: 0rem 0.7rem 0rem 0.7rem;
     display: flex;
     align-items: center;
     justify-content: center;
     margin-right: 0.5rem;
     border-radius: 1rem;
 }

 .page-container{
    background: rgb(21, 255, 0);
    display: flex;
    flex-direction: column;
   
 }

 .element-container{
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
    overflow: scroll;
 }


.element-top{
   height: fit-content;
   display: flex;
   flex-direction: row;
   align-items: center;
}

.element-title{
   font-size: 1.5rem;
   font-family: 'Fugaz One';
   text-transform: uppercase;
   margin-left: 0.5rem;
 }


.element-controls{
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    gap: 1rem;
    
}

.dropdown-exerciseName{
    flex-grow: 1;
}


.btn-exerciseName{
   
    color: white;
    border: none;
    width: 100%;
    overflow: hidden;
    padding: 0.2rem;
    text-align: left;
    text-overflow: ellipsis;
    flex-grow: 1;
}

.home-title{
    width: 100%;
    display: flex;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    padding-left: 0.5rem;
    text-transform: uppercase;

}





.home-line{
    display: flex;
    width: 25rem;
    max-width: 80vw;
    flex-direction: column;
    background-color: #2b313a;
    margin-top: 2rem;
    border-radius: 1.5rem;
    padding: 1rem;
   
}

.splash{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(180deg, #333944 0%, rgb(35, 42, 51) 100%);background: linear-gradient(180deg, #333944 0%, rgb(35, 42, 51) 100%);
    color: rgb(212, 211, 211);
}

.splashLogo{
    font-size: 4rem;
    font-weight: 400;
    font-family: 'Fugaz One';
    text-shadow: rgba(44, 186, 252, 0.932) 0 0 2rem;
    margin-bottom: 10rem;
}


.splashText{
    text-decoration: none;
    color: inherit;
    font-size: 2rem;
    font-weight: 400;
    font-family: 'Fugaz One';
    padding: 1rem;
}

.splashText:nth-child(1):hover{
    color: inherit;
    text-shadow: rgb(44, 186, 252) 0 0 1rem;
}

.splashText:nth-child(2):hover{
    color: inherit;
    text-shadow: rgb(44, 186, 252) 0 0 1rem;
}

  
  .container {
    min-width: 100vw;
    height: 100vh;
    overflow: scroll;
    position: relative;
    padding: 0;
    
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  
  .elements {
    min-width: 100vw;
    height: 100vh;
    
    
    display: flex;
    justify-content: center;
    align-items: center;
  }


  

  


  ::-webkit-scrollbar {
    display: none;
}


.controls-line{
    display: flex;
    
    flex-direction: column;
    background-color: #2b313a;
    margin-top: 2rem;
    border-radius: 1.5rem;
    padding: 1rem;
   
}

.controls-title{
    width: 100%;
    display: flex;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    padding-left: 0.5rem;
    text-transform: uppercase;
    font-family: 'Fugaz One';
}

.controls-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
   
}

.controls-item{
    color: white;
    flex-grow: 1;
    border-radius: 1rem;
    width: 100%;
    padding: 1rem;
    transition: scale .15s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
}

.controls-item:hover {
    scale: 1.05;
 }

.controls-icon{
    padding-bottom: 1rem;
}

.controls-item:nth-child(1){
    background: linear-gradient(0deg, rgba(197,107,34,1) 0%, rgba(233,140,39,1) 100%);
    
}

.controls-item:nth-child(2){
     background: linear-gradient(0deg, rgb(99, 41, 153) 0%, rgba(113, 47, 175) 100%);
}

.controls-item:nth-child(3){
   
    background: linear-gradient(0deg, rgb(17, 128, 41) 0%, rgba(21, 146, 48) 100%);
}

.btn-ello{
    background: none;
    color: white;
    border: none;
    width: 100%;
    overflow: hidden;
    padding: 0.2rem;
    text-align: left;
    text-overflow: ellipsis;
}




.dropdown-menu-exercise-hamburger{
    font-size: 1.1rem;
}


.dropdown-toggle::after {
    display: none;
  }



.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.dropdown-menu-exercise{
    width: 25rem;
    max-height: 20rem;
    overflow-y: scroll;
    overflow-x: hidden;
   
}

.form-control{
    padding: none !important;
    
}

.confirmationButtons{
    display: flex;
    gap: 1rem;
}
.confirmationButton{
    background-color: red;
    
  
    
}

.editTitleForm{
    width: 10rem;
    margin: 0;
    font-family: 'Arimo';
}

.editTitleButton{
    border: none;
    margin-left: 1rem;
    color: white;
    background:  rgb(30, 167, 71); 
    height: 100%;
    border: none;
    font-size: 1rem;
    border-radius: 0.5rem;
}

/* .overflowFadebottom{
    -webkit-mask-image: -webkit-gradient(linear, center top, center bottom, 
    color-stop(0.93,  rgba(0,0,0,1)),
    color-stop(1.00,  rgba(0,0,0,0)));
    mask-image: -webkit-gradient(linear, center top, center bottom, 
    color-stop(0.93,  rgba(0,0,0,1)),
    color-stop(1.00,  rgba(0,0,0,0)));
}

.overflowFadetop{
    -webkit-mask-image: -webkit-gradient(linear, center top, center bottom, 
    color-stop(0,  rgba(0,0,0,0)),
    color-stop(0.07,  rgba(0,0,0,1)));
    mask-image: -webkit-gradient(linear, center top, center bottom, 
    color-stop(0,  rgba(0,0,0,1)),
    color-stop(0.07,  rgba(0,0,0,0)));
} */


.timer{
    font-size: 2.6rem;
    font-weight: 400;
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333944;
    border-radius: 1rem;
    flex-direction: column;
    overflow: none;
    padding: 0.5rem;
}

.timer.hiit{
    font-size: 1.5rem;
    padding: 0.5rem;
}

.timer-maintext{
    padding: 1rem;
    height: 15rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.timer-maintext.stopwatch{
    font-size: 3rem;
}

.timer-circle.stopwatch{
    font-size: 1.3rem;
}

.timer-circle{ 
    background:  rgb(30, 167, 71); 
    padding: 0.5rem;
    border-radius: 1rem;
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    
    margin-bottom: 0.5rem;
}



.timer-circle.go{
    font-size: 1.7rem;
}



.break{
    background:  rgb(233, 155, 10);   
}

.hiitConfigForm{
    box-sizing: border-box;
    background: none;
    border: none;
    text-align: center;
    color: white;
    font-size: 1.2rem;
   
    width: 50%;
    height: 70%;
    padding: none;
}

.hiitConfigWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    padding: 1rem;
}

.hiitConfigItem{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    text-align: center;
}

.timer-next{
    font-size: 1.25rem;
}

.timer-break{
    font-size: 2.5rem;
    padding-bottom: 2rem;
}